import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2023/8D/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2023/8D/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/8D/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2023/8D/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2023 from 'views/ClassAssembly2023/ClassAssembly2023';

const ClassAssembly8D2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/8D/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/8D/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/8D/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/8D/4.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
        src: p3,
        source:p3,
        rows: 1,
        cols: 1,
      },
      {
        src: p4,
        source:p4,
        rows: 1,
        cols: 1,
      },

  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      {/* <Typography variant='h4' align='center'>
      EMPOWERING BOUNDARIES THROUGH ASSERTIVE COMMUNICATION
        </Typography> */}
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        Class: 8D   &  Date: 24 NOVEMBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of Class 8D presented their assembly on the topic ‘Empowering Boundaries Through Assertive Communication’ on Thursday 23 November 2023 and Friday, 24 November 2023.
        <br></br>
        The assembly began with an impactful thought for the day on assertiveness followed by the introduction of the topic. The day concluded with a song emphasising on the importance of assertive communication.
        <br></br> 
        On the second day, the students revisited the theme, presenting a thoughtful reflection on communication styles through a skit that vividly illustrated the impact and consequences of various approaches. The skit underscored the importance of assertive communication in expressing thoughts and feelings effectively.
        <br></br>
        The assembly garnered positive feedback from both the students and teachers, earning appreciation from the Principal for addressing a pertinent topic crucial for youngsters facing challenges in conveying their true emotions.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Empowering ourselves through Assertive communication”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2023 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly8D2023;